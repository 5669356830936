<template>
  <div class="profile">
    <mt-header :title="$t('profile')">
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div class="tips">
        <img src="../assets/safety.png">
        {{ $t('profileTips') }}
      </div>
      <div class="advertising">
        <img src="../assets/billing.png">
      </div>
      <div class="list">
        <div class="item" @click="onClick(1)">
          <div class="logo">
            <img src="../assets/personal.png" height="16px">
          </div>
          <div class="value">{{ $t('personalInfo') }}</div>
          <img v-if="currentStep > 1" class="right" src="../assets/success.png">
          <img v-if="currentStep == 1" class="arrow" src="../assets/arrow.png">
        </div>
        <div class="item" @click="onClick(2)">
          <div class="logo">
            <img src="../assets/work.png" height="15px">
          </div>
          <div class="value">{{ $t('workInfo') }}</div>
          <img v-if="currentStep > 2" class="right" src="../assets/success.png">
          <img v-if="currentStep == 2" class="arrow" src="../assets/arrow.png">
        </div>
        <div class="item" @click="onClick(3)">
          <div class="logo">
            <img src="../assets/kin.png" height="18px">
          </div>
          <div class="value">{{ $t('wontactInfo') }}</div>
          <img v-if="currentStep > 3" class="right" src="../assets/success.png">
          <img v-if="currentStep == 3" class="arrow" src="../assets/arrow.png">
        </div>
        <div class="item" @click="onClick(4)">
          <div class="logo">
            <img src="../assets/bankinfo.png" height="21px">
          </div>
          <div class="value">{{ $t('bankInfo') }}</div>
          <img v-if="currentStep > 4" class="right" src="../assets/success.png">
          <img v-if="currentStep == 4" class="arrow" src="../assets/arrow.png">
        </div>
        <div class="item" @click="onClick(5)">
          <div class="logo">
            <img src="../assets/photos.png" height="16.5px">
          </div>
          <div class="value">{{ $t('idcardInfo') }}</div>
          <img v-if="currentStep > 6" class="right" src="../assets/success.png">
          <img v-if="currentStep != 7 && currentStep >= 5" class="arrow" src="../assets/arrow.png">
        </div>
      </div>
    </div>
    <my-tabbar value="profile" />
    <mt-popup v-model="popupVisible">
      <div class="box">
        <img src="../assets/profileCreate.png" width="142px">
        <div class="titie">{{ $t('profileCreateInfo1') }}</div>
        <div class="info">{{ $t('profileCreateInfo2') }}</div>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import CallPhone from '../components/callPhone.vue'
import myTabbar from '../components/myTabbar.vue'
export default {
  components: { myTabbar, CallPhone },
  name: "Profile",
  data () {
    return {
      popupVisible: false,
    }
  },
  computed: {
    // ...mapState(["queryStatus"]),
    currentStep() {
      return 7
      // try {
      //   switch (this.queryStatus.apply.applyStatus) {
      //     case 'EMPTY':
      //       return 0;
      //     case 'APPLY':
      //       if (this.queryStatus.apply.isContinuedLoan == 'Y') {
      //         return 7
      //       }
      //       return this.queryStatus.apply.currentStep || 0
      //     default:
      //       return 7
      //   }
      // } catch(e) {
      //   return 7
      // }
    },
  },
  created() {
    // this.init();
  },
  methods: {
    // async init() {
    //   this.$indicator.open({
    //     spinnerType: "fading-circle",
    //   });
    //   this.popupVisible = true;
    //   await Promise.all([
    //     this.getQueryStatus(),
    //   ])
    //   this.popupVisible = false;
    //   this.$indicator.close();
    // },
    // async getQueryStatus() {
    //   await this.$axios({
    //     method: "get",
    //     url: queryStatus,
    //   }).then((e) => {
    //     if (e.status.code == "000") {
    //       this.SET_QUERY_STATUS(e.body);
    //     }
    //   }).catch(() => {})
    // },
    onClick(index) {
      if (index == 1 && this.currentStep == 1) {
        this.$router.push('/step/realnameauth')
      } else if (index == 2 && this.currentStep == 2) {
        this.$router.push('/step/jobauth')
      } else if (index == 3 && this.currentStep == 3) {
        this.$router.push('/step/relationshipauth')
      } else if (index == 4 && this.currentStep == 4) {
        this.$router.push('/step/bankInfo')
      } else if (index == 5 && this.currentStep > 4 && this.currentStep < 7) {
        this.$router.push('/step/idcardauth')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.profile {
  .content {
    .tips {
      font-size: 12px;
      color: #47B526;
      text-align: center;
      margin-top: 6px;
      img {
        width: 10px;
        vertical-align: middle;
        margin-right: 4px;
      }
    }
    .advertising {
      font-size: 0px;
      margin: 2px 6px 0px 6px;
      img {
        width: 100%;
      }
    }
    .list {
      .item {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #535353;
        height: 37px;
        border-bottom: 1px solid #DBDADA;
        .logo {
          margin-left: 30px;
          width: 50px;
          display: flex;
          align-items: center;
        }
        .value {
          flex: 1;
        }
        .right {
          width: 12px;
          margin-right: 25px;
          display: none; // 临时修改
        }
        .arrow {
          width: 9px;
          margin-right: 26px;
        }
      }
    }
  }
  .mint-popup {
    background-color: transparent;
  }
  .box {
    width: 220px;
    text-align: center;
    padding: 18px 12px;
    border-radius: 10px;
    background-color: white;
    .title {
      font-size: 14px;
      color: #000;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .info {
      font-size: 12px;
      color: #959595;
    }
  }
}
</style>